<template>
	<v-container fluid>
		<v-row>

		</v-row>
	</v-container>
</template>

<script>

export default {
	name: 'HomeIndex',
	components: {},
	data: () => ({}),
	methods: {},
	computed: {},
}
</script>

<style scoped>

</style>
